.RFS-StepperContainer {
    display: flex;
    margin-top: 80px;
    padding: 24px;
    align-items: flex-start;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    flex-direction: row;
    .RFS-StepContainer {
        flex: 0;
        position: relative;
        padding-left: 8px;
        padding-right: 8px;
        .RFS-StepMain {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .RFS-StepButtonContent {
                visibility: hidden;
            }
            button.active {
                background-color: #1abcf5;
            }
            button.completed {
                background-color: #1abcf5;
            }
        }
        .RFS-ConnectorContainer {
            width: 42px;
            height: 0px;
            top: 50%;
            left: -21px;
            right: calc(50% + 2em - 8px);
            position: absolute;
            opacity: 0.2;
            border: 1px solid #1abcf5;
        }

        .RFS-StepMain::before {
            content: "";
            width: 24px;
            position: absolute;
            height: 24px;
            border-radius: 50%;
            /* background: rebeccapurple; */
            border: 2px solid #1abcf5;
            pointer-events: none;
        }
        .RFS-StepButton {
            width: 14px;
            height: 14px;
            margin: 0 20px;
            background-color: #fff;
        }
        .RFS-StepButton-d0.active {
            background-color: #1abcf5;
        }
        .RFS-StepButton-d0.completed {
            background-color: #1abcf5;
        }
        .RFS-StepButton-d2.active {
            background-color: #1abcf5;
        }
        .RFS-StepButton-d2.completed {
            background-color: #1abcf5;
        }
        .RFS-StepButton-d4.active {
            background-color: #1abcf5;
        }
        .RFS-StepButton-d4.completed {
            background-color: #1abcf5;
        }
    }
}
.react-datepicker__input-container {
    input {
        width: 100% !important;
    }
}
.login-form .signIn-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    padding: 14px 24px;
    width: 400px;
    width: 100%;
    height: 48px;
    left: 946px;
    top: 564px;
    background: #1abcf5;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #ffffff;
}

.company-details {
    position: relative;
    width: 301px;
    height: 32px;
    margin-left: 93px;
    margin-top: 55px;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: rgba(0, 0, 0, 0.87);
}

#name,
#phone,
#dob,
#gender,
#password,
#confirmPassword,
#website,
#department,
#role,
#epmployeeID {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 24px 19px;
    position: static;
    width: 400px;
    width: 100%;
    left: calc(50% - 200px);
    top: 32.53%;
    bottom: 0%;
    background: #ffffff;
    border: 1px solid #1abcf5;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px;
}

#epmployeeID:disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
}

.fileUpload-wrapper {
    display: flex;
    align-items: center;
    margin: 27px 0;
    margin-left: 93px;
    .logo {
        width: 90px;
        height: 91px;
        background-image: url(../../Assets/images/fileUpload.png);
    }
    p {
        width: 155px;
        height: 12px;
        margin-left: 23px;
        margin-top: 7px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.6);
    }
    .image-preview-error {
        border: 2px solid red;
    }
    .image-upload-error {
        color: red;
        font-size: 12px;
        font-weight: 600;
    }
}

.fileUpload input[type="file"] {
    display: none;
}
.fileUpload {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e3e3e3;
    color: #333;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    padding: 6px 12px;
    font-size: 16px;
    cursor: pointer;
    margin-left: 23px;
    margin-top: 20px;
    width: 96px;
    height: 28px;
    // left: 1062px;
    // top: 239px;

    background: #ffffff;
    /* Primary */
    position: relative;
    border: 1px solid #1abcf5;
    box-sizing: border-box;
    border-radius: 6px;
}

.fileUpload > span {
    position: relative;
    width: 77px;
    height: 15px;
    // left: 11px;
    // top: 6px;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;

    color: #000000;
}

.stepper-3 {
    .RFS-StepperContainer {
        display: none;
        padding: 24px;
        align-items: flex-start;
        font-family: Arial, Helvetica, sans-serif;
        flex-direction: row;
    }
    .stepper-success {
        height: 900px;
        display: flex;
        align-items: center;
        .logo {
            i {
                font-size: 60px;
                color: #41b883;
            }
        }
        .title {
            width: 101px;
            height: 29px;
            left: 946px;
            top: 394px;
            margin: 24px 0 14px 0;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
        }

        .message {
            width: 400px;
            width: 100%;
            height: 44px;
            left: 947px;
            top: 437px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: 0.01em;
            color: rgba(0, 0, 0, 0.6);
        }
    }
}

.password-field,
.confirm-password-field {
    position: relative;
}
