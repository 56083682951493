// @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap');

::-webkit-scrollbar {
    width: 4px;
    background-color: red;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ddd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.notifications {
    overflow-y: auto;

    position: absolute;
    width: 365px;
    height: 341px;
    /* left: 1045px; */
    top: 71px;
    right: 5rem;
    opacity: 0;
    background: #f0fbff;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
    border-radius: 8px;
    // pointer-events: none;
    height: 0;
    display: none;
    h5 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .header {
        position: relative;
        width: 110px;
        height: 19px;
        left: 18px;
        top: 15px;
        margin-bottom: 24px;
        /* font-family: 'Montserrat'; */
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #000000;
    }

    .icon span {
        background: #f00;
        padding: 7px;
        border-radius: 50%;
        color: #fff;
        vertical-align: top;
        margin-left: -25px;
    }

    .icon img {
        display: inline-block;
        width: 26px;
        margin-top: 4px;
    }

    .icon:hover {
        opacity: 0.7;
    }

    .logo {
        flex: 1;
        margin-left: 50px;
        color: #eee;
        font-size: 20px;
        font-family: monospace;
    }
}

.visible {
    position: absolute;
    display: block;
    opacity: 1;
    width: 365px;
    height: 341px;
    top: 71px;
    right: 5rem;
    background: #f0fbff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 9999;
}

.notifications-item {
    width: 339px;
    height: 57px;
    left: 14px;
    top: 46px;
    background: #ffffff;
    border-radius: 4px;
    margin: 8px auto;
    .text {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 1rem;
        p {
            width: 272px;
            height: 19px;
            left: 9px;
            top: 18px;
            /* font-family: 'Montserrat'; */
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            letter-spacing: 0.005em;
            color: #000000;
        }
    }

    // display: flex;
    // border-bottom: 1px solid #eee;
    // padding: 6px 9px;
    // margin-bottom: 0px;
    // cursor: pointer;
}
.notifications-item.read {
    .text {
        p {
            cursor: pointer;
        }
        p:hover {
            text-decoration: underline;
        }
    }
}
.notifications-item.not-read {
    .text {
        p {
            font-weight: 700;
            cursor: pointer;
        }
        p:hover {
            text-decoration: underline;
        }
    }
}

// .notifications-item:hover {
//     background-color: #eee;
// }

@media screen and (max-width: 600px) {
    .visible {
        left: 50%;
        transform: translateX(-50%);
    }
}
