.outer-container {
    width: 400px;
    width: 100%;
    margin: auto;
}
.passwd-reset-text {
    position: relative;
    width: 400px;
    width: 100%;
    height: 44px;
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: rgba(0, 0, 0, 0.6);
}

.login-form .form-label {
    position: relative;
    width: 368px;
    top: 0%;
    bottom: 0%;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.005em;
    color: #11142d;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 14px 0 8px 17px;
}

.login-form .signIn-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 132px;
    padding: 14px 24px;
    width: 400px;
    width: 100%;
    height: 48px;
    left: 946px;
    top: 564px;
    background: #1abcf5;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #ffffff;
}
