.container {
    .edit-address-container {
        // width: 1263px;
        background: #ffffff;
        border-radius: 8px;
        margin: 0 auto;

        .rows {
            position: relative;
            top: 2rem;
            left: auto;
            margin: auto;
            width: 96%;

            .mb {
                margin-bottom: 1rem;
            }

            label {
                // width: 202px;
                height: 19px;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 120%;
                letter-spacing: 0.005em;
                color: #11142d;
                margin-left: 1rem;
            }

            .row-save-btn {
                margin: 2rem;
            }

            .col-save-btn {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .save-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 14px 24px;

                // position: absolute;
                width: 184px;
                height: 40px;
                left: 538px;
                top: 372px;

                /* Primary */

                background: #1abcf5;
                /* Primary */

                border: 2px solid #1abcf5;
                box-sizing: border-box;
                border-radius: 8px;
            }
        }

        input {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;

            position: static;
            // width: 380px;
            left: calc(50% - 380px / 2 - 10px);
            top: 40.3%;
            bottom: 0%;

            /* Light/Fills Color/Text Field */

            background: #ffffff;
            /* Primary */

            border: 1px solid #1abcf5;
            border-radius: 8px;

            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;
            // margin: 8px 0px;
        }

        .css-1s2u09g-control {
            border-width: 1px !important;
        }

        .css-319lph-ValueContainer {
            align-items: center;
            display: grid;
            flex: 1 1;
            -webkit-box-flex-wrap: wrap;
            flex-wrap: wrap;
            // padding: 14px 24px;
            -webkit-overflow-scrolling: touch;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            height: 40px;
        }

        .line {
            // width: 1239px;
            height: 0px;
            // left: 10px;
            top: 229px;
            margin: 2rem 0;
            /* Light/Separators Colors/Primary */
            border: 0.5px solid #e1e1fb;
        }
    }

    .title {
        // // width: 1263px;
        // height: 29px;
        // left: 144px;
        // top: 101px;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #11142d;
        margin-right: 6px;
    }
}

@media screen and (max-width: 768px) {
    .edit-address-container {
        width: 94%;
        overflow: auto;
        padding: 0 1rem;

        .col-save-btn {
            margin-bottom: 2rem;
        }
    }
}

@media screen and (min-width: 769px) {
    .edit-address-container {
        height: 100%;
        overflow: auto;

        .col-save-btn {
            margin-bottom: 2rem;
        }
    }
}

@media screen and (min-width: 992px) {
    .edit-address-container {
        height: 443px;
    }
}