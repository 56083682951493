// .landing-page-wrapper {
//     display: flex;
//     flex-wrap: wrap;
//     background: #ffffff;
//     margin: 0 auto;
// }

.column-left {
    padding-top: 2rem;
    background: #ffffff;

    .tandem-logo-wrapper {
        margin-bottom: 82px;
        // width: 270px;
        height: 80px;
        left: 82px;
        top: 66px;

        .logo {
            width: 100%;
            height: 100%;
            background-image: url(../../Assets/images/Tandem_logo_tagline_RGB_transparent1.png);
            background-repeat: no-repeat;
            object-fit: cover;
        }
    }

    .tandem-frame-wrapper {
        // width: 578px;
        height: 355px;
        left: 78px;
        top: 228px;
        margin-bottom: 59px;

        .frame {
            width: 75%;
            height: 100%;
            background-image: url(../../Assets/images/Frame.png);
            background-repeat: no-repeat;
            background-size: 100%;
            object-fit: cover;
        }
    }

    .work-from-home {
        height: 44px;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.01em;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 4rem;
        // white-space: nowrap;
    }

    .work-from-home-details {
        // background-color: red;
        // width: 630px;
        height: 88px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 8rem;
    }

    .ellipses {
        height: 14px;
        left: 82px;
        top: 823px;
    }
}

.column-right-conainer {
    background: rgba(72, 66, 154, 0.05);
    // background-color: red;
    margin: auto;

    min-height: 900px;

    .enter-cred-text {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 14rem !important;
    }

    .login-form {
        // background: beige;
        width: 100%;
        margin: auto;

        #email,
        #password {
            width: 100%;
        }

        .signIn-btn {
            width: 100%;
        }
    }
}