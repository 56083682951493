.edit-my-profile {
    width: 832px;
    height: 125%;

    .title {
        margin-top: 2rem;
        width: 254px;
        height: 29px;
        left: 360px;
        top: 113px;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #11142d;
    }

    .profile-card {
        display: flex;
        /* align-items: center; */
        padding: 0 1rem;
        margin-top: 1.5rem;
        margin-top: 1.5rem;
        padding: 0;
        width: 616px;
        height: 152px;
        border-radius: 8px;
        flex-direction: column;
        padding: 0 1rem;
        margin-top: 1.5rem;
        margin-top: 1.5rem;
        padding: 0;
        width: 616px;
        height: 152px;
        border-radius: 8px;

        .emp-details {
            height: 64px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .name {
                width: 88%;
                height: 58px;
                top: 285px;
                position: absolute;
                font-weight: bold;
                font-size: 1.5em;
                line-height: 120%;
                letter-spacing: 0.005em;
                color: #11142d;
                display: flex;
                line-break: anywhere;
                left: auto;
                bottom: 16px;
                margin-left: 5px;
            }
        }

        .img-wrapper {
            margin-bottom: 1rem;
            width: 104px;
            height: 107px;

            label {
                position: relative;

                .camera {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 29px;
                    height: 29px;
                    border-radius: 50%;
                    right: 0;
                    top: 0;
                    background: #1abcf5;
                    color: #fff;
                }
            }

            input {
                display: none;
            }

            .img-cover {
                display: inline-block;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 104px;
                height: 104px;
                border-radius: 50%;
                object-fit: cover;
                object-position: center right;
                background-color: #eee;

                .fa-user {
                    font-size: 2rem;
                    font-weight: 100;
                }
            }
        }
    }
}

.edit-form {
    position: relative;
    width: 832px;
    margin: 2rem auto;

    input::placeholder {
        /* font-family: Montserrat; */
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #9a9ab0;
        flex: none;
        order: 0;
        flex-grow: 1;
        margin: 0px 10px;
    }

    .form-label {
        position: relative;
        width: 368px;
        top: 0%;
        bottom: 0%;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.005em;
        color: #11142d;
        flex: none;
        order: 0;
        flex-grow: 1;
        margin: 24px 0 8px 17px;
    }

    .lable-password {
        margin-top: 24px;
    }

    .password-field {
        position: relative;
    }

    #password_1,
    #password_2,
    #email,
    #employee_id {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 18px 24px 19px;
        position: static;
        width: 400px;
        left: calc(50% - 400px / 2);
        top: 32.53%;
        bottom: 0%;
        background: #ffffff;
        border: 1px solid #1abcf5;
        border-radius: 8px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .gender {
        margin-bottom: 35px;
    }

    .update-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 24px;
        position: absolute;
        width: 400px;
        height: 48px;
        /* left: 360px; */
        /* top: 999px; */
        background: #1abcf5;
        border-radius: 8px;
        margin-top: 3rem;
    }

    .css-1s2u09g-control {
        border-width: 1px !important;
    }

    .css-319lph-ValueContainer {
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: grid;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-box-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 14px 24px;
        -webkit-overflow-scrolling: touch;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
    }
}

.react-datepicker__input-container>input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 24px 19px;
    position: static;
    width: 400px;
    left: calc(50% - 200px);
    top: 32.53%;
    bottom: 0%;
    background: #ffffff;
    border: 1px solid #1abcf5;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px !important;
}

@media screen and (max-width: 1000px) {
    .container.edit-my-profile {
        width: 96%;
        // background-color: red;
    }

    .edit-form {
        width: 100%;

        // background-color: blueviolet;
        input.form-control {
            width: 100% !important;
        }
    }

    .edit-form .update-btn {
        width: 100%;
    }

    .change-employer-modal .modal-content {
        width: 96% !important;

        .dropdown {
            width: 100% !important;
        }

        .form-control {
            width: 100% !important;
        }

        .update-btn {
            width: 100%;
            margin-top: 2rem;
        }
    }

    .pc-custom-modal .modal-content {
        width: 100%;

        .cp-form {
            width: 96% !important;

            .form-control {
                width: 100% !important;
            }

            .cpupdate-btn {
                width: 90% !important;
            }
        }
    }
}