@import '../../App.scss';
.main-container {
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    border: 0.5px solid #eee;
    margin: auto;
    height: 55rem;
    overflow: hidden;
    background: lavender;
}

.main-content {
    display: flex;
    // border: 0.5px solid #eee;
    // margin: auto;
    width: 100%;
    height: 100%;
}
// #page {
//     display: grid;
//     width: 100%;
//     height: 100vh;
//     grid-template-areas:
//         "head head"
//         "nav  main"
//         "nav  foot";
//     grid-template-rows: 80px 1fr;
//     grid-template-columns: 112px 1fr;
// }

// #page > header {
//     grid-area: head;
//     background: #ffffff;
//     border-bottom: 1px solid #e1e1e1;
// }

// #page > nav {
//     grid-area: nav;
//     background: #ffffff;
//     border-right: 1px solid #e1e1e1;
// }

// #page > main {
//     background-color: var(--container-color);
//     grid-area: main;
//     overflow-x: hidden;
//     overflow-y: auto;
// }


@media screen and (max-width: 767px) {
//    nav {
//     display: none;
//    }

   .main-container {
    height: auto;
   }
}