.allowances-table {
    height: 300px;
    table {
        width: 100%;
        tbody {
            height: 280px;
            overflow-y: auto;
        }
        th {
            width: 236px;
        }
        td {
            padding: 0 1rem;
        }
        .new-row {
            background-color: #eee;
            width: 1325px;
            .css-qc6sy-singleValue {
                color: gray;
            }
        }
    }

    .css-1s2u09g-control {
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 10px !important;
        border-style: solid;
        border-width: 1px !important;
        cursor: default;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        min-height: 38px;
        outline: none !important;
        position: relative;
        -webkit-transition: all 100ms;
        transition: all 100ms;
        box-sizing: border-box;
        background-color: hsl(0, 0%, 100%);
        border-color: hsl(0, 0%, 80%) !important;
        border-radius: 4px !important;
        border-style: solid;
        border-width: 0px !important;
    }

    #price {
        outline: none;
        border: none;
        width: 7rem;
    }
    .action-btn {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            padding: 0.375rem 0.375rem;
            outline: none;
            border: none;
            background-color: #fff;
        }
    }
}
