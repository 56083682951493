.container.dashboard {
    // max-width: 1440px;
    // margin: auto 0;
    background: lavender;
    height: 100%;
    padding: 1rem;
    // height: 900px;
    // overflow-y: scroll;
    // .row {
    //     height: 100%;
    // }
    padding-left: 2rem;

    .devider {
        display: none;
    }
}

.btn:active,
.btn:focus {
    background: #1abcf5;
    outline: none;
    border: none;
    box-shadow: #1abcf5;
}

.signIn-btn:hover {
    background: #1abcf5;
    outline: none;
    border: none;
}

.reimbursment-dropdowns {
    .first-row {

        .dept,
        .role {
            margin-bottom: 1rem;
        }
    }

    // width: 832px;
    // height: 134px;
    left: 144px;
    top: 100px;
    margin-bottom: 1rem;
    background: #ffffff;
    border-radius: 8px;
    padding: 18px 25px;

    .col {
        margin-bottom: 18px;
    }

    .start_date,
    .end_date {
        input {
            display: flex;
            flex-direction: row;
            align-items: center;
            /* padding: 18px 24px 19px; */
            padding: 8px;
            position: static;
            width: 100%;
            top: 32.53%;
            bottom: 0%;
            background: #ffffff;
            border: 1px solid #1abcf5;
            border-radius: 8px;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            margin: 0;
        }
    }
}

.reimbursment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 832px;
    height: 63px;
    left: 144px;
    top: 250px;
    margin-bottom: 24px;
    background: #fa5491;
    border-radius: 8px;
    padding: 0 17px;

    .text {
        width: 184px;
        height: 19px;
        left: 161px;
        top: 273px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #ffffff;
    }

    .sum {
        min-width: 60px;
        height: 22px;
        left: 900px;
        top: 271px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #ffffff;
    }
}

.reimbursment-chart {
    height: 60%;
    left: 144px;
    top: 337px;
    background: #ffffff;
    border-radius: 8px;
    padding: 10px 32px;


    .firstRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 16px 0;

        button {
            background: #1abcf5;
            outline: none;
            border: none;
        }

        span {
            width: 185px;
            height: 19px;
            left: 160px;
            top: 362px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: 0.005em;
            color: #11142d;
        }

        .download-btn {
            width: auto;
            font-family: Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            letter-spacing: 0.005em;
            color: #000000;
        }
    }

    .line {
        // width: 800px;
        width: 100%;
        height: 0px;
        left: 160px;
        top: 408px;
        background: #1abcf5;
        border: 1px solid #1abcf5;
        margin-bottom: 24px;
    }
}

.reimbursment-table {
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;

    tbody {
        height: 95%;
    }

    // width: 400px;
    height: 92.5%;
    left: 1008px;
    top: 100px;
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;

    p {
        position: relative;
        width: 47px;
        height: 19px;
        left: 16px;
        top: 18px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #11142d;
        margin-bottom: 28px;
    }

    .download-btn {
        width: auto;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #000000;
    }

    // button {
    //     background: #1abcf5;
    //     outline: none;
    //     border: none;
    // }
}

.reimbursment-table {
    width: 100%;
    min-height: 630px;
    table-layout: fixed;
    border-collapse: collapse;

    tbody {
        display: block;
        overflow-y: scroll;
        height: 500px;
    }

    thead tr {
        display: block;
    }

    th,
    td {
        margin: 0;
        padding: 1rem;
        border-bottom: 0.5px solid #e1e1fb;
        // padding: 5px;
        // text-align: left;
        width: 225px;
    }
}

.go-btn {
    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 24px;
        // width: 378px;
        width: 100%;
        height: 40px;
        left: 429px;
        top: 76px;

        /* Primary */

        background: #1abcf5;
        /* Primary */

        border: 2px solid #1abcf5;
        box-sizing: border-box;
        border-radius: 8px;
    }
}

@media screen and (max-width: 768px) {
    .container.dashboard {
        overflow-y: auto;
        overflow-x: hidden;
        // margin: auto 0;
        // background: lavender;
        // height: 100%;
        padding: 0;

        .devider {
            display: block;
            background-color: lavender;
            width: 100%;
            height: 2rem;
        }
    }

    .dashboard-container {
        background: lavender;
        width: 100%;
        padding: 0;
        overflow: auto;

        .row {
            flex-direction: column;
        }

        .reimbursment-chart {
            margin-bottom: 2rem;
        }


        .reimbursment-dropdowns {

            .start_date,
            .end_date {
                margin-bottom: 1rem;
            }
        }

        .react-datepicker__input-container {
            input {
                width: 100px;
            }
        }
    }
}

@media screen and (min-width: 767px) {
    .reimbursment-chart {
        margin-bottom: 2rem;
    }

    .reimbursment-dropdowns {

        .start_date,
        .end_date {
            margin-bottom: 1rem;
        }
    }
}

@media screen and (max-width: 991px) {
    .reimbursment-table {
        height: 600px;
        overflow-y: scroll;
        margin-top: 1rem;
    }
}