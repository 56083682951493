.employee-profile {
    .title-wrapper {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3rem;

        .release-btn {
            width: 200px;
            height: 40px;
            outline: none;
            border: 1px solid #fa5491;
            background: lavender;
            color: #fa5491;
            border-radius: 8px;
            margin-bottom: 1rem;

            span {
                width: 154px;
                height: 19px;
                left: calc(50% - 154px / 2);
                top: calc(50% - 19px / 2);
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 120%;
                text-align: center;
                letter-spacing: 0.005em;
                color: #fa5491;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin: 0px 10px;
                white-space: nowrap;
            }
        }

        .text {
            width: 218px;
            height: 29px;
            left: 143px;
            top: 108px;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 120%;
            letter-spacing: 0.005em;
            color: #11142d;
            margin-bottom: 1rem;
        }

        .buttons {
            width: 400px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .release-btn {
                visibility: hidden;
            }
        }

        .cancel-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;

            background: #fff;
            color: #9a9ab0;
            outline: none;
            border: none;

            span {
                width: 39px;
                height: 13px;
                left: 23.5px;
                top: 9.5px;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 110%;
                text-align: center;
                letter-spacing: 0.008em;
                color: #9a9ab0;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin: 0px 10px;
            }
        }

        .approve-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;

            background: #61c36a;
            outline: none;
            border: none;

            span {
                width: 50px;
                height: 13px;
                left: 18px;
                top: 9.5px;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 110%;
                text-align: center;
                letter-spacing: 0.008em;
                color: #ffffff;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin: 0px 10px;
            }
        }
    }

    .p-card {
        // position: relative;
        // min-height: 300px;
        // margin: 1rem 0;
        // /* left: 144px; */
        // /* top: 164px; */
        // background: #ffffff;
        // border-radius: 8px;

        // for scroll

        position: relative;
        height: 300px;
        margin: 1rem 0;
        overflow-y: scroll;
        /* left: 144px; */
        /* top: 164px; */
        background: #ffffff;
        border-radius: 8px;



        .profile-details {
            margin: auto;
            display: flex;
            /* align-items: center; */
            justify-content: space-between;

            .edit-profile {
                outline: none;
                border: none;
                background: #ffffff;
            }

            .user-img {
                width: 72px;
                height: 72px;
                border-radius: 50%;
                /* left: 14px; */
                /* top: 19px; */
                background: #eee;
                margin-right: 0.3rem;

                img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 72px;
                    height: 72px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center right;
                    background-color: #eee;
                }
            }

            .user-details {
                max-height: 100px;
                width: 200px;
                /* background: red; */
                display: flex;
                flex-direction: column;
                // align-items: center;
                justify-content: space-around;

                .name {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    white-space: nowrap;
                    width: 100%;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 120%;
                    letter-spacing: 0.005em;
                    color: #11142d;

                    .emp-name {
                        width: 80%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .vertical-line,
                    .gender {
                        font-size: 10px;
                    }
                }

                .designation {
                    width: 141px;
                    height: 22px;
                    left: 109px;
                    top: 54px;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 120%;
                    /* identical to box height, or 22px */

                    letter-spacing: 0.005em;

                    /* Light/Text Color/Title */

                    color: #11142d;
                    width: 132%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .department {
                    width: 124px;
                    width: 100%;
                    height: 22px;
                    left: 109px;
                    top: 84px;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 120%;
                    /* identical to box height, or 22px */

                    letter-spacing: 0.005em;

                    /* Light/Text Color/Title */

                    color: #11142d;
                }
            }
        }


        .header {
            padding: 1rem;

            .title {
                margin: 0;
                width: 250px;

                .text {
                    width: 100%;
                    height: 22px;
                    margin-left: 1rem;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 120%;
                    letter-spacing: 0.005em;
                    color: #11142d;
                }
            }
        }

        .line {
            width: 94%;
            margin: auto;
            height: 0px;
            left: 8px;
            top: 51px;
            border: 0.5px solid #e1e1fb;
        }
    }

    .profile {
        .header {
            height: 120px;
        }

        .body {
            height: 60%;

            .details {
                width: 90%;
                margin: auto;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .detail {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    span:nth-child(1) {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 120%;
                        letter-spacing: 0.005em;
                        color: #11142d;
                    }
                }
            }
        }
    }

    .attachments {
        .body {
            height: 60%;
            overflow-y: auto;

            .downloads {
                // background-color: red;
                width: 90%;
                margin: auto;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .download {
                    // background-color: yellow;
                    margin: 0.5rem 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .btn:focus {
                        outline: none;
                        box-shadow: none;
                    }

                    button {
                        background: #fff;
                        color: gray;
                        border: none;
                        outline: none;
                    }

                    span:nth-child(1) {
                        flex: 1 1 0;
                    }

                    span:nth-child(2) {
                        margin-right: 0.5rem;
                    }

                    span:nth-child(3) {
                        margin-left: 1rem;
                    }

                    span {
                        i {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: 2rem;
                            height: 2rem;
                            width: 2rem;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }

    .address {
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 3.5rem;

            .edit-hra {
                cursor: pointer;
            }

            .approve-btn {
                background: #61c36a;
                color: #ffffff;
                outline: none;
                border: none;
                padding: 0.5rem 1rem;
                font-size: 12px;
                font-weight: 700;
                letter-spacing: 0px;
            }
        }

        .body {
            .address {
                font-style: normal;
                font-weight: normal;
                font-size: 0.8rem;
                line-height: 120%;
                letter-spacing: 0.005em;
                color: #11142d;
            }

            .ml-auto {
                margin-left: auto;
            }

            .new-hra-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #eee;
                padding: 6px;
                margin: 2px 6px;
                gap: 12px;

                // span {
                // :nth-child(1) {
                //     width: 60% !important;
                // }

                // :nth-child(2) {
                //     width: 20%;
                //     text-align: start;
                // }

                // :nth-child(3) {
                //     text-align: end;
                //     width: 20%;
                // }
                // }

                .new-hra-box-title {
                    font-style: normal;
                    font-size: 0.8rem;
                    letter-spacing: 0.005em;
                    color: #11142d;
                    // white-space: ;
                    font-weight: bold;
                }

                .new-hra-box-hra {
                    width: fit-content;
                    font-style: normal;
                    font-size: 0.8rem;
                    color: #61c36a;
                    font-style: normal;
                    line-height: 120%;
                    letter-spacing: 0.005em;
                    color: #11142d;
                    display: inline;
                    position: relative;

                    .fa-times {
                        position: absolute;
                        top: 8px;
                        right: -6px;
                        width: 1rem;
                        padding: 0.2rem;
                        cursor: pointer;
                    }
                }

                .home_allowance {
                    display: block;
                    width: fit-content;
                    padding: 0rem !important;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #212529;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: none;
                    outline: none !important;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    border-radius: 0.25rem;
                    transition: border-color 0.15s;
                    border-bottom: 1px solid #000 !important;

                    &:focus,
                    &:active {
                        border: none !important;
                        box-shadow: none;
                        border-bottom: 1px solid #000 !important;
                    }
                }
            }

            .assigned-hra {
                span {
                    color: #61c36a !important;
                    font-style: normal;
                    font-size: 0.8rem;
                    line-height: 120%;
                    display: flex;
                    align-items: flex-end;
                    letter-spacing: 0.005em;
                    font-weight: bold;
                }

                :nth-child(1) {
                    width: 80% !important;
                }

                :nth-child(2) {
                    width: 33%;
                    text-align: start;
                }

                :nth-child(3) {
                    text-align: end;
                    width: 20%;
                }
            }

            .assigned-hra2 {
                span {
                    // color: #61c36a !important;
                    font-style: normal;
                    font-size: 0.8rem;
                    line-height: 120%;
                    display: flex;
                    align-items: flex-end;
                    letter-spacing: 0.005em;
                    // font-weight: bold;
                }

                :nth-child(1) {
                    width: 60% !important;
                }

                :nth-child(2) {
                    width: 20%;
                    text-align: start;
                }

                :nth-child(3) {
                    text-align: end;
                    width: 20%;
                }
            }
        }

        .footer {
            .btn-container {
                width: 100%;
                height: 3rem;
                margin-top: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 1rem;

                .btn:focus {
                    outline: none;
                    box-shadow: none;
                }

                button {
                    outline: none;
                    border: none;
                    width: 100px;
                    height: 100%;
                    left: 18px;
                    top: 9.5px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 110%;
                    text-align: center;
                    letter-spacing: 0.008em;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin: 0px 10px;
                }

                .approve-btn {
                    background: #61c36a;
                    color: #ffffff;
                }

                .reject-btn {
                    background: #fff;
                    border: 1px solid #9a9ab0;

                    color: #11142d;
                }
            }
        }
    }

    .assigned-allowance {
        .allowance-header {
            padding: 1rem;

            .title {
                margin: 0;
                width: 240px;
                height: 20%;

                .text {
                    margin: 0 1rem;
                    width: 202px;
                    height: 22px;
                    left: 49px;
                    top: 18px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 120%;
                    letter-spacing: 0.005em;
                    color: #11142d;
                    white-space: nowrap;
                }
            }
        }

        .body {
            height: 60%;

            .allowances {
                width: 90%;
                /* background: red; */
                margin: auto;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .allowance {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }

        .footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 16%;
            width: 90%;
            margin: auto;

            .allowance {
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 120%;
                    letter-spacing: 0.005em;
                    color: #11142d;
                }
            }
        }
    }
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.flex-container>div {
    flex: 0 0 26%;
}

.edit-profile-modal {
    .modal-content {
        position: relative;
        display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;
        /* width: 100%; */
        max-width: 454px !important;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;

        .modal-header {
            width: 100%;
        }

        form {
            width: 100%;
            margin: auto;

            #employeeID,
            #nameID,
            #departmentID {
                width: 100%;
            }
        }

        label {
            position: relative;
            width: 368px;
            top: 0%;
            bottom: 0%;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;
            display: flex;
            align-items: flex-end;
            letter-spacing: 0.005em;
            color: #11142d;
            flex: none;
            order: 0;
            flex-grow: 1;
            margin: 16px 0 8px 17px;
        }

        #employeeID,
        #nameID,
        #departmenID,
        #roleID {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 18px 24px 19px;
            position: static;
            width: 420px;
            left: calc(50% - 420px / 2);
            top: 32.53%;
            bottom: 0%;
            background: #ffffff;
            border: 1px solid #1abcf5;
            border-radius: 8px;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            margin: 8px 0px;
        }
    }
}

@media (max-width: 768px) {
    .employee-profile {
        .title-wrapper {
            width: 100% !important;

            .release-btn {
                width: 100% !important;
            }

            .text {
                width: 100% !important;
            }
        }
    }
}

@media (max-width: 320px) {
    .flex-container>div {
        flex-basis: 51%;
    }
}

.attachments-footer {

    /* ===================== FILE INPUT ===================== */
    .file-area {
        width: 100%;
        position: relative;

        .upload-btn {
            position: absolute;
            top: 0;
            /* width: 100%; */
            height: 100%;
            right: 0;

            button {
                /* width: 100%; */
                height: 100%;
                /* opacity: 0.5; */
                right: 0px;
                // z-index: 999;
                background: #1abcf5;
                border: none;
                outline: none;
            }
        }

        input[type="file"] {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
        }

        .file-dummy {
            height: 60px;
            width: 100%;
            // padding: 16px;
            margin-top: -24px;
            /* background: green; */
            /* width: 80%; */
            border: 2px dashed #1abcf5;
            text-align: center;
            transition: background 0.3s ease-in-out;

            .default,
            .success {
                line-height: 60px;
            }

            .success {
                // display: none;
                display: flex;
                align-items: center;

                span {
                    margin-left: 0.5rem;
                }
            }
        }

        &:hover .file-dummy {
            background: rgba(255, 255, 255, 0.1);
        }

        input[type="file"]:focus+.file-dummy {
            outline: 2px solid #1abcf5;
            outline: none;
        }

        input[type="file"]:valid+.file-dummy {
            border-color: rgba(0, 255, 0, 0.4);

            .default {
                display: none;
            }
        }
    }
}