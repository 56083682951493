.my-profile {
    width: 100%;
    position: relative;
    margin: 2rem;

    // .title {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // margin-bottom: 1.75rem;

    .spantitle {
        // width: 218px;
        // height: 29px;
        // left: 143px;
        // top: 108px;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #11142d;
        margin-bottom: 1.75rem;
    }

    // }

    .profile {
        .profile-card {
            // min-width: 25rem;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            margin-bottom: 1.5rem;
            padding: 0 1rem;
            flex: 0 0 40%;
            height: 152px;
            left: 144px;
            top: 151px;
            background: #ffffff;
            border-radius: 8px;

            .emp-details {
                margin-left: 1.5rem;
                height: 64px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                overflow: hidden;

                .name {
                    width: 100%;
                    height: 29px;
                    left: 217px;
                    top: 37px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 120%;
                    letter-spacing: 0.005em;
                    color: #11142d;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .ID {
                    width: 86px;
                    height: 22px;
                    left: 217px;
                    top: 74px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 120%;
                    letter-spacing: 0.005em;
                    color: #11142d;
                }
            }

            .img-wrapper {
                width: 104px;
                height: 107px;
                margin-left: 1rem;

                label {
                    position: relative;

                    .camera {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 29px;
                        height: 29px;
                        border-radius: 50%;
                        right: 0;
                        top: 0;
                        background: #1abcf5;
                        color: #fff;
                    }
                }

                input {
                    display: none;
                }

                .img-cover {
                    display: inline-block;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 104px;
                    height: 104px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center right;
                    background-color: #eee;

                    .fa-user {
                        font-size: 2rem;
                        font-weight: 100;
                    }
                }
            }
        }

        // .box-1,
        // .box-2 {
        //     flex: 0 0 20%;
        // }
        .box-2 {
            display: flex;
            flex-direction: column;
            /* align-items: center; */
            justify-content: space-between;

            div {
                // min-width: 292px;
                height: 64px;
                left: 792px;
                top: 151px;
                background: #ffffff;
                border-radius: 8px;
            }

            .personal-details,
            .professional-details,
            .change-employer,
            .change-password {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 1.5rem;

                img {
                    margin: 0 1rem;
                }

                button {
                    // margin: 1rem;
                    outline: none;
                    border: none;
                    background: #fff;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                .tag {
                    // width: 131px;
                    height: 19px;
                    left: 68px;
                    top: 22px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 120%;
                    display: flex;
                    align-items: flex-end;
                    letter-spacing: 0.005em;
                    color: #000000;
                }
            }
        }

        .chat-card {
            width: 616px;
            height: 64px;
            left: 144px;
            top: 327px;
            background: #ffffff;
            border-radius: 8px;
            margin-top: -2rem;
            min-width: 616px;
            display: flex;
            align-items: center;
            padding: 0 1rem;
            flex: 0 0 40%;
            left: 144px;
            top: 151px;
            background: #ffffff;
            border-radius: 8px;

            .icon {
                outline: none;
                border: none;
                background: #fff;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .text {
                margin-left: 1rem;
                width: 160px;
                height: 19px;
                left: 68px;
                top: 22px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 120%;
                display: flex;
                align-items: flex-end;
                letter-spacing: 0.005em;
                color: #000000;
            }
        }
    }
}

.flex-container {
    width: calc(100% - 2rem);
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.p-card {
    position: relative;
    // min-width: 400px;
    // height: 300px;
    /* left: 144px; */
    /* top: 164px; */
    background: #ffffff;
    border-radius: 8px;

    .profile-details {
        margin: auto;
        display: flex;
        /* align-items: center; */
        justify-content: space-between;

        .edit-profile {
            outline: none;
            border: none;
            background: #ffffff;
        }

        .user-img {
            border-radius: 50%;
            width: 88px;
            height: 88px;
            left: 21px;
            top: 62px;
            background: #fa5491;
            font-size: 35px;
            color: #fde848;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: xxx-large;
            text-transform: uppercase;
        }

        .user-wrapper {
            position: relative;

            .gender-details {
                position: absolute;
                top: 6px;
                right: 0;

                .gender {
                    position: absolute;
                    top: 5px;
                    right: 16px;
                }

                .vertical-line {
                    width: 1px;
                    height: 12px;
                    background: black;
                    position: absolute;
                    top: 12px;
                    right: 60px;
                }
            }
        }

        .user-details {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .name {
                width: 145px;
                height: 29px;
                left: 109px;
                top: 19px;
                /* font-family: Montserrat; */
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 120%;
                letter-spacing: 0.005em;
                color: #11142d;
            }

            .designation {
                width: 158px;
                left: calc(50% - 158px / 2 + 18px);
                top: 19.3%;
                bottom: 74.27%;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 120%;
                display: flex;
                align-items: flex-end;
                letter-spacing: 0.005em;
                color: #11142d;
            }

            .department {
                width: 239px;
                height: 16px;
                left: 166px;
                top: 102px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 120%;
                letter-spacing: 0.005em;
                color: #11142d;
            }

            .address {
                width: 269px;
                left: calc(50% - 269px / 2 + 73.5px);
                top: 36.84%;
                bottom: 58.48%;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 120%;
                letter-spacing: 0.005em;
                color: #11142d;
            }
        }
    }

    .header {
        padding: 1rem;

        .title {
            margin: 0;
            width: 150px;
            /* background: red; */
            /* min-width: 190px; */
            // display: flex;
            // align-items: center;
            // justify-content: space-evenly;

            .text {
                width: 125px;
                height: 22px;
                left: 49px;
                top: 18px;
                margin-left: 1rem;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 120%;
                /* identical to box height, or 22px */

                letter-spacing: 0.005em;

                /* Light/Text Color/Title */

                color: #11142d;
            }
        }
    }

    .line {
        width: 94%;
        margin: auto;
        height: 0px;
        left: 8px;
        top: 51px;
        border: 0.5px solid #e1e1fb;
    }
}

.professional-detail-modal {
    .modal-content {
        width: 454px;
        height: 342px;
        background: #ffffff;
        border-radius: 8px;

        .modal-body {
            position: relative;
            flex: 1 1 auto;
            padding: 0rem;

            .joining-letter {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.profile {
    .body {
        height: 60%;

        .details {
            width: 90%;
            height: 164px;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .detail {
                display: flex;
                align-items: center;
                justify-content: space-between;

                span:nth-child(1) {
                    // position: absolute;
                    // width: 121px;
                    // height: 22px;
                    // left: 14px;
                    // top: 130px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 120%;
                    letter-spacing: 0.005em;
                    color: #11142d;
                }
            }
        }
    }
}

.change-employer-modal {
    .custom-modal {
        justify-content: center;

        .css-1s2u09g-control {
            border-width: 1px !important;
            height: 40px;
        }

        .mb-2 {
            margin-bottom: 2rem !important;
        }
    }

    .modal-content {
        width: 847px;
        min-height: 635px;

        input {
            margin-bottom: 2rem;
        }

        #jobTitle,
        #location,
        #empID {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 18px 24px 19px;
            position: static;
            width: 400px;
            height: 40px;
            left: calc(50% - 200px);
            top: 32.53%;
            bottom: 0%;
            background: #ffffff;
            border: 1px solid #1abcf5;
            border-radius: 8px;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            // margin: 8px 0px;
        }

        label {
            width: 248px;
            height: 19px;
            left: 42px;
            top: 69px;

            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;
            /* or 19px */

            letter-spacing: 0.005em;

            /* Light/Text Color/Title */

            color: #11142d;
        }
    }

    .update-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 24px;
        position: absolute;
        width: 400px;
        height: 48px;
        /* left: 360px; */
        /* top: 999px; */
        background: #1abcf5;
        border-radius: 8px;
        margin-top: 3rem;
    }

    .file-wrapper {
        height: 40px;
        display: flex;
        flex-direction: row;
        /* background: red; */
        // margin-top: 60px;
        align-items: center;
        width: 90%;

        .image-upload {
            width: 104px;
            height: 28px;
            left: 21px;
            top: 113px;

            background: #ffffff;
            /* Primary */

            border: 1px solid #1abcf5;
            box-sizing: border-box;
            border-radius: 6px;

            label {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            span {
                width: 44px;
                height: 15px;
                left: 42px;
                top: 6px;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
            }
        }

        .image-upload>input {
            display: none;
        }

        .file-name {
            width: 179px;
            left: calc(50% - 179px / 2 + 124px);
            top: 66.77%;
            bottom: 30.24%;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;
            display: flex;
            align-items: flex-end;
            letter-spacing: 0.005em;
            color: #11142d;
            margin-bottom: 1rem;
        }
    }
}

.pc-custom-modal {
    .modal-content {
        height: 432px;
        width: 100%;

        .modal-body {
            .cp-form {
                margin-top: -1rem !important;
                width: 400px;
                margin: auto;

                .cpupdate-btn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 14px 24px;
                    position: absolute;
                    width: 400px;
                    height: 48px;
                    /* left: 360px; */
                    /* top: 999px; */
                    background: #1abcf5;
                    border-radius: 8px;
                    margin-top: 3rem;
                }

                #password1,
                #password2 {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 18px 24px 19px;
                    position: static;
                    width: 400px;
                    left: calc(50% - 200px);
                    top: 32.53%;
                    bottom: 0%;
                    background: #ffffff;
                    border: 1px solid #1abcf5;
                    border-radius: 8px;
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 0;
                    margin: 8px 0px;
                }

                label {
                    position: relative;
                    width: 368px;
                    top: 0%;
                    bottom: 0%;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 120%;
                    display: flex;
                    align-items: flex-end;
                    letter-spacing: 0.005em;
                    color: #11142d;
                    flex: none;
                    order: 0;
                    flex-grow: 1;
                    margin: 24px 0 8px 17px;
                }

                .password_1-field,
                .password_2-field {
                    position: relative;

                    .eye-icon {
                        cursor: pointer;
                        position: absolute;
                        top: 3rem;
                        right: 20px;
                        font-weight: 900;
                        width: 23px;
                        height: 16px;
                    }

                    .text-danger {
                        margin-top: -0.75rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .flex-container1 {
        width: 82%;

        .profile-card {
            margin-bottom: 1.5rem;
        }
    }
}

@media screen and (max-width: 767px) {
    .flex-container1 {
        width: 82%;
    }

    .professional-detail-modal {
        .modal-content {
            max-width: 454px;
            width: 100%;

            // height: 342px;
            // background: #ffffff;
            // border-radius: 8px;
            // .modal-body {
            //     position: relative;
            //     flex: 1 1 auto;
            //     padding: 0rem;
            //     .joining-letter {
            //         white-space: nowrap;
            //         overflow: hidden;
            //         text-overflow: ellipsis;
            //     }
            // }
        }
    }

    .my-profile .profile .profile-card {
        min-width: 100%;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        flex: 0 0 40%;
        height: 152px;
        left: 144px;
        top: 151px;
        background: #ffffff;
        border-radius: 8px;
    }

    // .flex-container, .box-2 {
    //     width: 20rem;
    // }
}