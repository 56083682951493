nav {
    // position: fixed;
    height: calc(55rem - 80px);
    // height: 100vh;
    // width: 80px;

    .brand {
        width: 2.5rem;
        height: 2.5rem;
        background-image: url(../../Assets/images/tandem-icon1.png);
        background-repeat: no-repeat;
        background-size: 100%;
        display: none;
        margin-bottom: 0.5rem;
    }
}

nav>div {
    padding-top: 0.75rem;
    background: #fff;
    height: calc(55rem - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;

    // margin: 40px auto;
    // height: calc(100% - 56px);
    .icon-container {
        height: 80px;
        width: 80px;
        display: inherit;
        align-items: center;
        justify-content: center;

        &.settings {
            flex-basis: 45%;
            display: inherit;
            align-items: end;
            justify-content: center;
        }
    }
}

/* DASHBOARD ICON CSS*/
.dashboard>.active {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #1abcf5;
}

.dashboard>.active>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    border-radius: 8px;
    background-image: url(../../Assets/images/dashboard_active.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

.dashboard>.inactive>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    background-image: url(../../Assets/images/dashboard.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

.dashboard {
    .bs-tooltip-end .tooltip-arrow:before {
        right: -1px;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-right-color: red !important;
    }

    .tooltip-inner {
        max-width: 200px;
        padding: 0.25rem 0.5rem;
        color: #fff;
        text-align: center;
        background-color: red !important;
        border-radius: 0.25rem;
    }
}

/* ANALYTICS ICON CSS*/
.invite>.active {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #1abcf5;
}

.invite>.active>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    border-radius: 8px;
    background-image: url(../../Assets/images/analytics_active.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

.invite>.inactive>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    background-image: url(../../Assets/images/analytics.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

/* CUSTOMER ICON CSS*/
.customer>.active {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #1abcf5;
}

.customer>.active>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    border-radius: 8px;
    background-image: url(../../Assets/images/customer.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

.customer>.inactive>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    background-image: url(../../Assets/images/customer.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

/* PRODUCT ICON CSS*/
.product>.active {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #1abcf5;
}

.product>.active>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    border-radius: 8px;
    background-image: url(../../Assets/images/product.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

.product>.inactive>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    background-image: url(../../Assets/images/product.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

/* COUPON ICON CSS*/
.allowance>.active {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #1abcf5;
}

.allowance>.active>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    border-radius: 8px;
    background-image: url(../../Assets/images/coupon_active.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

.allowance>.inactive>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    background-image: url(../../Assets/images/coupon.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

.active {
    cursor: pointer;
}

.inactive {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}

/* SETTINGS ICON CSS*/
// .settings > .active {
//     width: 56px;
//     height: 56px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 8px;
//     background: #1abcf5;
// }

.settings {
    width: 56px;
    margin-top: 2rem;
}

.settings>.active>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    border-radius: 8px;
    background-image: url(../../Assets/images/settings.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

.settings>.inactive>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    background-image: url(../../Assets/images/settings.png);
    background-repeat: no-repeat;
    // line-height: 56px;
}

.active {
    cursor: pointer;
}

.inactive {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}

/* EMPLOYEE PROFILE ICON CSS*/
.profile>.active {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #1abcf5;
}

.profile>.active>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    border-radius: 8px;
    background-image: url(../../Assets/images/profile_active.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

.profile>.inactive>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    background-image: url(../../Assets/images/profile.png);
    background-repeat: no-repeat;
    line-height: 56px;
}

/* EMPLOYEE ADDRESS ICON CSS*/
.address>.active {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #1abcf5;

    i {
        color: #fff;
        font-size: 20px;
    }
}

.address>.inactive {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    i {
        color: #1abcf5;
        font-size: 20px;
    }
}

.address>.active>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    border-radius: 8px;
    line-height: 56px;
}

.address>.inactive>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    line-height: 56px;
}

/* EMPLOYEE TERMS AND CONDITIONS ICON CSS*/
.terms-conditions>.active {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #1abcf5;

    i {
        color: #fff;
        font-size: 20px;
    }
}

.terms-conditions>.inactive {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    i {
        color: #1abcf5;
        font-size: 20px;
    }
}

.terms-conditions>.active>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    border-radius: 8px;
    line-height: 56px;
}

.terms-conditions>.inactive>.icon {
    width: 24px;
    height: 24px;
    line-height: 56px;
    text-align: center;
    line-height: 56px;
}

.hamburger {
    display: none;
}

@media screen and (max-width: 600px) {
    .sidebar-wrapper {
        height: 100vh;
    }

    .marginLeft {
        margin-left: 0px;
        transition: all;
        min-height: 100vh;
    }

    nav {
        // display: none;
        margin-left: -100%;
        width: 100%;
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        min-height: 100vh;
        // width: 320px;
        /* background: #000; */
        /* opacity: 0.5; */
        background-color: rgba(0, 0, 0, 0.5);

        .brand {
            display: block;
        }
    }

    nav.show {
        display: block;
    }

    .hamburger {
        display: block;
    }
}