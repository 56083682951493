header {
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    background: #fff;
    max-width: 1440px;
    height: 80px;
}

.flex {
    // min-width: 600px;
    margin: 0 25px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item-left {
        display: flex;
        align-items: center;
        width: 135px;
        height: 40px;

        .brand {
            width: 180px;
        }

        .hamburger {
            margin-right: 1.5rem;

            .fa-bars {
                font-size: 24px;
                cursor: pointer;
            }
        }
    }

    .item-right {
        width: 15%;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .profile-details-link {
            text-decoration: none;
            color: #9a9ab0;
            font-size: 12px;
            line-height: 120%;
            letter-spacing: 0.008em;
            color: #9a9ab0;
        }

        .profile-details-link:hover {
            text-decoration: none;
            color: #9a9ab0;
        }

        .profile-details {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 220px;
        }

        .details {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 2.5rem;
        }

        .details>.company {
            // width: 143px;
            height: 19px;
            // margin-top: 21px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: 0.005em;
            color: #11142d;
        }

        .details>.name {
            height: 2.5rem;
            width: 77px;
            height: 14px;
            left: 1283px;
            top: 40px;

            /* Captions/Captions Regular */

            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 120%;
            /* or 14px */

            letter-spacing: 0.008em;

            /* Light/Text Color/Body 2 */

            color: #9a9ab0;
        }

        .avatar {
            vertical-align: middle;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }

        .bell-icon {
            position: relative;

            // width: 18px;
            // height: 20px;
            button {
                background: #fff;
                border: none;
                outline: none;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            i {
                font-size: 20px;
                color: #9a9ab0;
            }

            span {
                background: red;
                position: absolute;
                top: 2px;
                right: 2px;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                font-size: 10px;
                color: #fff;
                text-align: center;
                line-height: 16px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    header {
        .flex {
            padding-right: 2rem;

            .profile-details {
                .details {
                    display: none;
                }
            }
        }
    }
}