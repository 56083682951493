.terms-n-conditions {
    padding: 2rem;
    width: 60%;
    height: 100vh;
    margin: auto;
    /* width: 754px; */
    /* height: 788px; */
    overflow-y: auto;
    background: #fff;
    .title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 3rem;
    }
}

.agreements {
    width: 95px;
    height: 20px;
    left: 38px;
    top: 662px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #231f20;
}

@media screen and (max-width: 992px) {
    .terms-n-conditions {
        width: 96%;
    }
}
