.container {

    .spanTitle {
        top: 101px;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #11142d;
    }

    .address-container {
        background: #ffffff;
        border-radius: 8px;
        margin: 0 auto;

        .rows {
            padding-top: 1rem;
            left: auto;
            margin: auto;
            width: 96%;

            .first-row {
                margin-bottom: 1.5rem;
            }

            .box {
                .box-title {
                    height: 19px;
                    left: 178px;
                    top: 177px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 120%;
                    letter-spacing: 0.005em;
                    color: #11142d;
                }

                .box-body {
                    height: 19px;

                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 120%;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.005em;
                    color: rgba(0, 0, 0, 0.87);
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin: 1rem 0;
                }
            }
        }

        .line {
            height: 0px;
            margin: 1.5rem 0;
            border: 0.5px solid #e1e1fb;
        }

        .box-hra {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.75rem;
        }

        .home_office_usage-text,
        .home_allowance-text {
            height: 19px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: 0.005em;
            color: #11142d;
        }

        .home_allowance,
        .home_office_usage {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
            display: flex;
            align-items: flex-end;
            letter-spacing: 0.005em;
            color: #61c36a;
        }

        .home_office_usage {
            .text-black {
                color: #000;
            }

            .middle {
                padding: 0 0.5rem;
            }
        }

        .pending {
            bottom: 11.11%;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 120%;
            display: flex;
            align-items: flex-end;
            letter-spacing: 0.005em;
            color: rgb(226, 19, 19);
            border: 1px solid rgb(226, 19, 19);
            width: 100px;
            width: 100px;
            height: 75%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: auto;
        }
    }
}

.last-row.box-hra {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    .text-hra {
        width: 282px;
        left: calc(50% - 282px / 2 - 471.5px);
        top: 83.18%;
        bottom: 11.11%;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.005em;
        color: #11142d;
    }

    .amount {
        width: 59px;
        left: calc(50% - 59px / 2 - 1px);
        top: 23.64%;
        bottom: 23.64%;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.005em;
        color: #61c36a;
    }
}

@media screen and (max-width: 600px) {
    .address-container {
        padding: 0 1rem;
        padding-bottom: 2rem;
        width: 94%;
        height: 88%;
    }
}

@media screen and (max-width: 768px) {
    .address-container {
        // height: 100%;
        width: 94%;

        .text-hra {
            margin-bottom: 1rem;
        }

        .last-row.box-hra {
            padding-bottom: 3rem;
        }
    }
}

@media screen and (min-width: 769px) {
    .address-container {
        height: 400px;
        overflow: auto;
    }
}

@media screen and (min-width: 992px) {
    .address-container {
        height: 320px;
    }
}