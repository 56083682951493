.how-to {
    position: relative;
    width: 57px;
    height: 17px;
    margin-left: auto;
    top: 49px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #000000;
}
.enter-cred-text {
    position: relative;
    width: 301px;
    height: 32px;
    margin-left: 93px;
    margin-top: 233px;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: rgba(0, 0, 0, 0.87);
}

.login-form {
    position: relative;
    width: 400px;
    margin: auto;
    input::placeholder {
        /* font-family: Montserrat; */
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #9a9ab0;
        flex: none;
        order: 0;
        flex-grow: 1;
        margin: 0px 10px;
    }
    .form-label {
        position: relative;
        // width: 368px;
        top: 0%;
        bottom: 0%;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.005em;
        color: #11142d;
        flex: none;
        order: 0;
        flex-grow: 1;
        margin: 34px 0 8px 17px;
    }
    .lable-password {
        margin-top: 24px;
    }
    .password-field {
        position: relative;
    }
    #password,
    #email {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 18px 24px 19px;

        position: static;
        // width: 400px;
        left: calc(50% - 400px / 2);
        top: 32.53%;
        bottom: 0%;

        /* Light/Fills Color/Text Field */

        background: #ffffff;
        /* Primary */

        border: 1px solid #1abcf5;
        border-radius: 8px;

        /* Inside auto layout */

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 8px 0px;
    }
    .eye-icon {
        cursor: pointer;
        position: absolute;
        top: 50px;
        right: 20px;
        font-weight: 900;
        width: 23px;
        height: 16px;
        i {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .signIn-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        padding: 14px 24px;
        // width: 400px;
        height: 48px;
        left: 946px;
        top: 564px;

        /* Primary */

        background: #1abcf5;
        border-radius: 8px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.005em;

        /* Light/Text Link Color/White */

        color: #ffffff;
    }
    .forgot-password {
        height: 19px;
        left: 1199px;
        top: 505px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        text-align: right;
        letter-spacing: 0.005em;
        a {
            color: #9a9ab0;
            text-decoration: none;
        }
    }
    .your-email {
        position: absolute;
        // width: 352px;
        left: calc(50% - 176px);
        top: 48px;
        bottom: 33.93%;
        /* font-family: Montserrat; */
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #9a9ab0;
        flex: none;
        order: 0;
        flex-grow: 1;
        margin: 0px 10px;
    }
    .your-password {
        position: absolute;
        width: 79px;
        height: 19px;
        left: calc(50% - 176px);
        top: 48px;
        /* font-family: Montserrat; */
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: 0.005em;
        color: #9a9ab0;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
    }
}
.create-account {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 136px; */
    height: 17px;
    top: 90px;
    /* left: 93px; */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    a {
        color: #fa5491;
        text-decoration: underline;
    }
}
.circle-or {
    position: relative;
    top: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        display: inherit;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        /* background: #606060; */
        background: rgba(72, 66, 154, 0.05);
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }
}
