.users-table {
    // width: 96%;
    margin: 0 auto;
    table {
        width: 100%;
    }
    .active-department {
        background: #eee;
    }
}
table {
    thead {
        /* width: 386px; */
        height: 50px;
        left: 8px;
        top: 47px;
        background: rgba(26, 188, 245, 0.1);
    }
    border-spacing: 0;
    /* border: 1px solid black; */

    tr {
        :last-child {
            td {
                border-bottom: 0;
            }
        }
    }

    th {
        height: 19px;
        left: 72px;
        top: 64px;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */

        letter-spacing: 0.005em;

        /* Light/Text Color/Title */

        color: #11142d;
    }

    td {
        height: 19px;
        left: 72px;
        top: 112px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */

        letter-spacing: 0.005em;

        /* Light/Text Color/Title */

        color: #11142d;
    }

    th,
    td {
        margin: 0;
        padding: 1rem;
        border-bottom: 0.5px solid #e1e1fb;
        /* border-right: 1px solid black; */

        :last-child {
            border-right: 0;
        }
    }
}
