.modal-header {
    border-bottom: 0 solid #dee2e6;
    div {
        width: 157px;
        height: 19px;
        left: 21px;
        top: 14px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #11142d;
    }
}
.modal-footer {
    border-top: 0 solid #dee2e6;
    height: 64px;
    justify-content: center;
    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 11px 24px;
        position: absolute;
        width: 167px;
        height: 40px;
        background: #1abcf5;
        border-radius: 8px;
    }
}

