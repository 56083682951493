.wrapper {
    background: lavender;
    width: 100%;
    height: 100%;
    max-width: 1366px;
    margin: auto;
}

.title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex-wrap: wrap;
    position: relative;
    // top: 1rem;
    margin: auto;

    .title-manage {
        width: 254px;
        height: 29px;
        left: 30px;
        top: 46px;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #11142d;
    }

    .assign-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 11px 24px;
        width: 105px;
        height: 40px;
        left: 1188px;
        top: 41px;
        background: #1abcf5;
        border-radius: 8px;
        outline: none;
        border: none;

        span {
            position: static;
            width: 57px;
            height: 19px;
            left: calc(50% - 57px / 2);
            top: calc(50% - 19px / 2);
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            letter-spacing: 0.005em;
            color: #ffffff;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 0px 10px;
        }
    }
}

.manageAllowance {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: 1rem;
    margin: auto;
}

.column {
    min-width: 350px;
    width: 100%;
    height: 300px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 16px;
    overflow-y: auto;
}

.allowance-card,
.departments-card,
.roles-card {
    // width: 100%;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;

        span {
            width: 97px;
            height: 19px;
            left: 46px;
            top: 135px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: 0.005em;
            color: #11142d;
        }

        .add-icon {
            outline: none;
            border: none;
            background: #ffffff;
        }
    }

    .body {
        max-height: 270px;
        overflow-y: auto;
    }
}

.add-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    label {
        position: static;
        width: 388px;
        left: calc(50% - 388px / 2);
        top: 0%;
        bottom: 0%;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.005em;
        color: #11142d;
        flex: none;
        order: 0;
        flex-grow: 1;
        margin: 0px 10px;
    }

    label.department {
        margin: 16px 0 10px 10px;
    }

    .name {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 18px 24px 19px;
        position: static;
        width: 400px;
        top: 32.53%;
        bottom: 0%;
        background: #ffffff;
        border: 1px solid #1abcf5;
        border-radius: 8px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 8px 0px;
    }
}

/* ASSIGN ALLOWANCE CSS */

.assign-allowance {
    // width: 1440px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: 32px;
    // width: 96%;
    margin: auto;

    .allowance {
        padding: 16px 16px 0 16px;
        background: #fff;

        .allowance-frequency {
            margin: 8px 0;
        }

        .roles-table {
            label {
                margin-bottom: 16px;
            }
        }

        label {
            width: 193px;
            height: 19px;
            left: 63px;
            top: 126px;
            margin-left: 12px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: 0.005em;
            color: #11142d;
        }
    }
}

.total-sum-row {
    margin: 8px 0;

    .total-sum-col {
        display: flex;
        align-items: center;

        span:nth-child(1) {
            margin-left: 16px;
        }

        span:nth-child(2) {
            margin-left: 172px;
        }
    }

    .buttons-row {
        // margin-left: 60%;
        display: flex;
        justify-content: center;

        button {
            background: #1abcf5;

            span {
                width: 118px;
                height: 19px;
                left: calc(50% - 118px / 2);
                top: calc(50% - 19px / 2);
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 120%;
                text-align: center;
                letter-spacing: 0.005em;
                color: #ffffff;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin: 0px 10px;
            }
        }
    }
}

.manage-allowance {
    .modal-content {
        padding: 1rem 0.5rem;
        max-width: 400px;
        margin: auto;

        form {
            width: 100%;

            .modal-body .add-wrapper>div {
                width: 100%;

                .form-control {
                    width: 100% !important
                }
            }
        }
    }
}