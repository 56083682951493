.react-datepicker__input-container > input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 24px 19px;
    position: static;
    width: 400px;
    left: calc(50% - 200px);
    top: 32.53%;
    bottom: 0%;
    background: #ffffff;
    border: 1px solid #1abcf5;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px;
}
