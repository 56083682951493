// .dashboard-container {
//     max-width: 1440px;
//     margin: auto;
// }
.table-container {
    width: 87%;
    background: #fff;
    margin: 1rem auto;
    min-height: 640px;
    height: 100%;
    .filters {
        padding: 2rem 1rem;
        .dropdown {
            .css-1s2u09g-control {
                border-width: 1px !important;
                height: 40px;
            }
        }
        .go-btn button:hover {
            background-color: #1abcf5;
            outline: none;
            border: none;
        }
        .go-btn {
            button {
                outline: none;
                border: none;
                background: #1abcf5;
            }
            span {
                width: 23px;
                height: 19px;
                left: calc(50% - 23px / 2);
                top: calc(50% - 19px / 2);
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 120%;
                text-align: center;
                letter-spacing: 0.005em;
                color: #ffffff;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin: 0px 10px;
            }
        }
    }
    .table-row {
        padding: 1rem;
        &.p {
            width: 71px;
            height: 19px;
            left: 20px;
            top: 134px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: 0.005em;
            color: #11142d;
        }
    }
}

.title {
    max-width: 87%;
    margin: auto;
    margin-top: 1rem;
    height: 29px;
    left: 144px;
    top: 133px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #11142d;
}

.from,
.to {
    input {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        position: static;
        left: calc(50% - 200px);
        top: 32.53%;
        bottom: 0%;
        background: #ffffff;
        border: 1px solid #1abcf5;
        border-radius: 8px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 0;
        height: 40px;
    }
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

@media screen and (max-width: 600px) {
    .users-table {
        overflow-x: auto;
    }
}

@media screen and (max-width: 992px) {
    .start_date,
    .end_date {
        margin-bottom: 1rem;
    }
}
