.analytics-container {
    background: lavender;
    height: 100%;
    // padding: 20px 32px;
    max-width: 1440px;
    margin: auto;

    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    :-moz-placeholder {
        color: pink;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: 0.008em;
        color: #9a9ab0;
        flex: none;
        order: 1;
        flex-grow: 1;
        margin: 0px 8px;
    }

    .search-box {
        position: relative;
        margin-bottom: 16px;

        label {
            position: absolute;
            top: 10px;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);
        }

        input {
            width: 100%;
            height: 40px;
            left: 13px;
            top: 15px;
            background: rgba(26, 188, 245, 0.1);
            border-radius: 8px;
            padding: 10px 48px;
            border: none;
            outline: none;
        }
    }

    .dropdowns {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        // margin-bottom: 22px;
        // .go-btn {
        //     width: 16%;
        //     height: 40px;
        //     background: #1abcf5;
        // }
    }

    .go-btn {
        width: 100%;
        height: 40px;
        background: #1abcf5;
    }

    .analytics-table {
        overflow: hidden;
        position: relative;
        /* width: 833px; */
        height: 712px;
        /* left: 144px; */
        // top: 115px;
        background: #ffffff;
        border-radius: 8px;
        padding: 15px 13px;

        .view {
            height: 28px;
            background: #fa5491;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            border: none;
        }
    }

    .invite-new-employee {
        background: #fff;
        height: 100%;
        min-height: 476px;
        padding: 2px 0;

        .inputs {
            margin-top: 51.5px;
        }

        p {
            position: relative;
            width: 179px;
            height: 19px;
            left: 18px;
            top: 21px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: 0.005em;
            color: #11142d;
        }

        .request-form {
            width: calc(100% - 36px);
            margin: 40px auto;

            input {
                height: 39px;
                max-width: 100%;
                min-width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 18px 24px 19px;
                position: static;
                width: 400px;
                left: calc(50% - 200px);
                top: 32.53%;
                bottom: 0%;
                background: #ffffff;
                border: 1px solid #1abcf5;
                border-radius: 8px;
                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;
                margin: 8px 0px;
            }

            .invite {
                display: flex;
                align-items: center;
                justify-content: end;
                margin-top: 18px;
            }

            .invite-btn,
            .import-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 14px 24px;
                /* position: absolute; */
                width: 111px;
                height: 40px;
                left: 270px;
                top: 333px;
                background: #1abcf5;
                border: 2px solid #1abcf5;
                box-sizing: border-box;
                border-radius: 8px;
            }
        }

        .import {
            // width: calc(100% - 36px);
            height: 40px;
            position: relative;
            margin: auto;
            margin: 2.5rem 1rem;

            p {
                position: absolute;
                top: 20%;
                width: 149px;
                height: 19px;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 120%;
                letter-spacing: 0.005em;
                color: #11142d;
                left: 0;
            }

            .import-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 14px 24px;
                position: absolute;
                width: 111px;
                height: 40px;
                right: 0;
                background: #1abcf5;
                border: 2px solid #1abcf5;
                box-sizing: border-box;
                border-radius: 8px;

                span {
                    position: static;
                    width: 48px;
                    height: 19px;
                    left: calc(50% - 48px / 2);
                    top: calc(50% - 19px / 2);
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 120%;
                    text-align: center;
                    letter-spacing: 0.005em;
                    color: #ffffff;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin: 0px 10px;
                }
            }
        }
    }

    .request-cards {
        width: 400px;
        height: 218px;
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: 1rem;
        background: #ffffff;

        .request-card {
            width: 399px;
            height: 212px;
            background: #ffffff;
            border-radius: 8px;
            margin-bottom: 0.5rem;

            .header {
                position: relative;
                width: 159px;
                height: 19px;
                left: 17px;
                top: 17px;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 120%;
                letter-spacing: 0.005em;
                color: #11142d;
            }

            .body {
                height: 151px;
                width: calc(100% - 36px);
                background: #ffffff;
                border: 1px solid #e1e1fb;
                box-sizing: border-box;
                border-radius: 8px;
                position: relative;
                height: 151px;
                left: 8px;
                top: 36px;
                background: #ffffff;
                border: 1px solid #e1e1fb;
                box-sizing: border-box;
                border-radius: 8px;
                position: relative;
                width: 384px;
                height: 151px;
                background: #ffffff;
                border: 1px solid #e1e1fb;
                box-sizing: border-box;
                border-radius: 8px;

                .profile {
                    display: flex;
                    align-items: flex-start;
                    padding: 16px;

                    .details {
                        display: inherit;
                        /* align-items: center; */
                        justify-content: center;
                        flex-direction: column;
                    }

                    .image {
                        background: #eee;
                        min-height: 64px;
                        min-width: 64px;
                        border-radius: 50%;

                        img {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 64px;
                            height: 64px;
                            border-radius: 50%;
                            object-fit: cover;
                            object-position: center right;
                            background-color: #eee;
                        }
                    }

                    .details {
                        margin-left: 15px;

                        span {
                            width: 168px;
                            height: 14px;
                            left: 104px;
                            top: 92px;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 120%;
                            letter-spacing: 0.005em;
                            color: #11142d;
                        }

                        .name {
                            height: 19px;
                            left: 103px;
                            top: 67px;
                            font-size: 16px;
                            font-weight: bold;
                        }

                        p {
                            width: 168px;
                            height: 14px;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 120%;
                            letter-spacing: 0.005em;
                            color: #11142d;
                        }
                    }

                    .name {
                        width: 168px;
                        height: 19px;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 120%;
                        letter-spacing: 0.005em;
                        color: #11142d;
                    }
                }

                .action-btn {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    padding: 0.5rem 1rem;

                    .approve,
                    .cancel {
                        margin-left: 16px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 3px 8px;
                        width: 86px;
                        height: 32px;
                        left: 296px;
                        top: 159px;
                        border-radius: 4px;
                    }

                    .approve {
                        background: #61c36a;
                        outline: none;
                        border: none;

                        span {
                            width: 50px;
                            height: 13px;
                            left: 18px;
                            top: 9.5px;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 12px;
                            line-height: 110%;
                            text-align: center;
                            letter-spacing: 0.008em;
                            color: #ffffff;
                            flex: none;
                            order: 0;
                            flex-grow: 0;
                            margin: 0px 10px;
                        }
                    }

                    .cancel {
                        background: #fff;
                        color: #9a9ab0;
                        outline: none;
                        border: none;
                        border: 1px solid #9a9ab0;

                        span {
                            width: 39px;
                            height: 13px;
                            left: 23.5px;
                            top: 9.5px;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 12px;
                            line-height: 110%;
                            text-align: center;
                            letter-spacing: 0.008em;
                            color: #9a9ab0;
                            flex: none;
                            order: 0;
                            flex-grow: 0;
                            margin: 0px 10px;
                        }
                    }
                }
            }
        }
    }
}

/* CSS FOR MODAL BODY */
.file-wrapper {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .image-upload {
        width: 104px;
        height: 28px;
        left: 21px;
        top: 113px;

        background: #ffffff;
        /* Primary */

        border: 1px solid #1abcf5;
        box-sizing: border-box;
        border-radius: 6px;

        label {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }

    .download-csv {
        width: 170px;
        height: 28px;
        left: 21px;
        top: 113px;
        background: #ffffff;
        border: 1px solid #1abcf5;
        box-sizing: border-box;
        border-radius: 6px;
        margin-left: 8px;

        label {
            a {
                text-decoration: none;
                color: black;
            }
        }


    }

    .image-upload>input {
        display: none;
    }

    .file-name-container {
        display: flex;
        width: 100%;
        height: 29px;
        left: 21px;
        top: 72px;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #11142d;

        .uploading-text,
        .error-text {
            margin-left: 6rem;
            font-size: 16px;
            font-weight: 200;
        }

        .error-text {
            font-weight: 400;
        }
    }
}

/* */

.invite-new-employee {
    width: 400px;

    .invite-form {
        padding: 0 1rem;

        .text-danger {
            margin: -8px 0;
        }

        width: 100%;
        // max-width: 363px;
        margin: auto;

        #name,
        #email,
        #employee_id {
            padding: 6px 12px;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: static;
            width: 100%;
            max-width: 363px;
            left: calc(50% - 200px);
            top: 32.53%;
            bottom: 0%;
            background: #ffffff;
            border: 1px solid #1abcf5;
            border-radius: 8px;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            height: 40px;
            margin: 0.5rem auto;
        }

        .dropdown {
            max-width: 363px !important;
            width: 100% !important;
            margin: 0.5rem auto;

            .css-1s2u09g-control {
                border-width: 1px !important;
            }
        }

        .invite {
            // width: 363px;
            margin: 1rem auto;
            display: flex;
            align-items: center;
            justify-content: end;

            button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 14px 24px;
                width: 111px;
                height: 40px;
                left: 270px;
                top: 333px;
                background: #1abcf5;
                border: 2px solid #1abcf5;
                box-sizing: border-box;
                border-radius: 8px;

                span {
                    position: static;
                    width: 48px;
                    height: 19px;
                    left: calc(50% - 48px / 2);
                    top: calc(50% - 19px / 2);
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 120%;
                    text-align: center;
                    letter-spacing: 0.005em;
                    color: #ffffff;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin: 0px 10px;
                }
            }
        }
    }
}

.analytics.users-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    overflow-x: auto;

    table {
        min-width: 650px;
    }

    tbody {
        display: block;
        overflow: auto;
        height: 500px;
    }

    thead tr {
        display: block;
    }

    th,
    td {
        margin: 0;
        padding: 1rem;
        border-bottom: 0.5px solid #e1e1fb;
        width: 225px;
    }
}

@media screen and (max-width: 600px) {
    .action-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center !important;
        padding: 0.5rem 1rem;
    }

    .btns-wrapper {
        flex-direction: column !important;
    }
}

@media only screen and (max-width: 992px) {
    .analytics-container {
        .row {
            flex-direction: column-reverse;
        }

        .invite-new-employee,
        .request-cards {
            width: 100%;
        }

        .request-cards {
            margin-bottom: 2rem;
        }
    }
}