.token-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 413px;
    height: 56px;
    input {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 57px !important;
        height: 56px;
        left: calc(50% - 28.5px);
        top: 32.53%;
        bottom: 0%;
        background: #ffffff;
        border: 1px solid #1abcf5;
        border-radius: 8px;
        flex: none;
        order: 1;
        flex-grow: 0;
        /* margin: 8px 0px; */
        margin-right: 32px;
    //     border: none;
    // outline: none;
    }
    input:focus {
        border: 1px solid #1abcf5 !important;
    }
}
.confirm-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 132px;
    padding: 14px 24px;
    width: 400px;
    width: 100%;
    height: 48px;
    left: 946px;
    top: 564px;
    background: #1abcf5;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #ffffff;
}
